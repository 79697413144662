import { Box } from "@mui/material";
import styles from "./appLoader.module.css";
interface DarkAppLoaderProps {
  text?: string | null;
}

export default function AppLoader() {
  return (
    <Box component="div" className={styles.container}>
      <span className={styles.loader}></span>
    </Box>
  );
}

export function DarkAppLoader({ text = null }: DarkAppLoaderProps) {
  return (
    <Box component="div" className={styles.container} style={{
      display:"flex",
      flexDirection:"column"
    }}>
      <span className={styles.darkLoader}></span>
      {text && <p>{text}</p>}   
    </Box>
  );
}

