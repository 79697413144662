import { createStore } from "state-pool";

const store = createStore();

store.persist({
  PERSIST_ENTIRE_STORE: false,
  saveState: function (key, value, isInitialSet) {
    try {
      const serializedState = JSON.stringify(value);
      window.localStorage.setItem(key, serializedState);
    } catch (error: any) {
      console.error("Error saving state to session storage", error);
      return undefined;
    }

    if (isInitialSet) {
      const serializedState = JSON.stringify(value);
      window.localStorage.setItem(key, serializedState);
    }
  },
  loadState: function (key, noState) {
    try {
      const serializedState = window.localStorage.getItem(key);
      if (serializedState === null) {
        return noState;
      }
      return JSON.parse(serializedState);
    } catch (error: any) {
      console.error("Error loading state from session storage", error);
      return noState;
    }
  },
  removeState: function (key) {
    window.localStorage.removeItem(key);
  },
  clear: function () {
    window.localStorage.clear();
  },
});

const sessionStore = createStore();

sessionStore.persist({
  PERSIST_ENTIRE_STORE: true,
  saveState: function (key, value, isInitialSet) {
    try {
      const serializedState = JSON.stringify(value);
      window.sessionStorage.setItem(key, serializedState);
    } catch (error: any) {
      console.error("Error saving state to session storage", error);
      return undefined;
    }

    if (isInitialSet) {
      const serializedState = JSON.stringify(value);
      window.sessionStorage.setItem(key, serializedState);
    }
  },
  loadState: function (key, noState) {
    try {
      const serializedState = window.sessionStorage.getItem(key);
      if (serializedState === null) {
        return noState;
      }
      return JSON.parse(serializedState);
    } catch (error: any) {
      console.error("Error loading state from session storage", error);
      return noState;
    }
  },
  removeState: function (key) {
    window.sessionStorage.removeItem(key);
  },
  clear: function () {
    window.sessionStorage.clear();
  },
});

export { store, sessionStore };
