import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00BB6C",
    },
    secondary: {
      main: "#FFFF",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h1: {
      fontSize: "1.25rem",
    },
  },
});

export { theme };
