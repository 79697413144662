import { useEffect, useState } from "react";

export interface UserData {
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
  username: string;
  organisation: string;
  designation: string;
  country: string;
  fto_search: ServiceDetails;
  invalidity_search: ServiceDetails;
  patentability_search: ServiceDetails;
  claim_charting: ServiceDetails;
  validity_search: ServiceDetails;
  org_name: string;
  default_true: ServiceDetails;
  default_false: ServiceDetails;
  [key: string]: any;
}

export interface ServiceDetails {
  enabled: boolean;
  trial: boolean;
  limit: number;
  trial_period_hrs: number;
  trial_start_timestamp: string;
}

interface UseAuthHookReturnType {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: UserData | null;
  handleLogout: Function;
}

// This hook is used to check if the user is authenticated.
// If the user is authenticated, it returns the user data.
// If the user is not authenticated, it returns null.
// additionally, it returns a function to logout the user.
// if refresh token is valid but access token is expired, it will refresh the access token.
export function useAuth(): UseAuthHookReturnType {
  // authState is the state of the authentication.
  // isLoading is true if the authentication is in progress.
  // isAuthenticated is true if the user is authenticated.
  // user is the user data if the user is authenticated. Otherwise, it is null.
  // handleLogout is a function to logout the user.
  const [authState, setAuthState] = useState<UseAuthHookReturnType>({
    isLoading: true,
    isAuthenticated: false,
    user: null,
    handleLogout: () => null,
  });

  // This useEffect is called when the component is mounted.
  useEffect(() => {

    /** This function is used to logout the user. **/
    const handleLogout = async () => {
      try {
        const amsUrl = process.env["REACT_APP_AMS_URL"];
        if (amsUrl) {
          const url = amsUrl + "/token";
          await fetch(url, { credentials: "include", method: "DELETE" });
          const languageCode = localStorage.getItem("i18nextLng") || "en";
          localStorage.clear();
          localStorage.setItem("i18nextLng", languageCode);
          // sessionStorage.clear();
          window.location.assign("/");
        }
      } catch (error) {
        console.error(error);
      }
    };
    
    /**  Fetch the user data from the backend and validate it  **/
    const getAuthState = async () => {
      try {
        const url = process.env["REACT_APP_AMS_URL"];
        if (url) {
          const tempServiceObj = { limit: 0, trial: false };

          // if the AMS url is present, we will call the backend to fetch the user data.
          // we will call the backend with credentials: "include" to send the cookies to the backend.
          const response = await fetch(url, { credentials: "include" });

          // if the response status is 400, it means that the access token is expired.
          if (response.status === 400) {
            // if the access token is expired, we will call the backend to refresh the access token.
            const freshTokenUrl = url + "/token";
            const response = await fetch(freshTokenUrl, { method: "POST", credentials: "include" });
            // if the response status is 401, it means that the refresh token is expired.
            if (response.status === 401 || response.status === 400) {
              // if the refresh token is expired, we set the authState to isAuthenticated: false.
              setAuthState({ isLoading: false, isAuthenticated: false, user: null, handleLogout: handleLogout });
              return;
            }

            // if the response status is 200, it means that the access token is refreshed.
            if (response.status === 200) {
              // we will call the backend again to fetch the user data.
              const response = await fetch(url, { credentials: "include" });

              // update the userFetchTime in the local storage.
              localStorage.setItem("userFetchTime", new Date().toString());
              const data = await response.json();

              // set the authState to isAuthenticated: true and user: userData.
              const userData: UserData = {
                ...data.detail,
                default_false: { enabled: false, ...tempServiceObj },
                default_true: { enabled: true, ...tempServiceObj },
              };

              // update the user data in the local storage.
              localStorage.setItem("user", JSON.stringify(userData));

              setAuthState({ isLoading: false, isAuthenticated: true, user: userData, handleLogout: handleLogout });
              return;
            }
          }

          // if response status is 200 it means that the user is authenticated.
          if (response.status === 200) {
            localStorage.setItem("userFetchTime", new Date().toString());
            const data = await response.json();
            const userData: UserData = {
              ...data.detail,
              default_false: { enabled: false, ...tempServiceObj },
              default_true: { enabled: true, ...tempServiceObj },
            };
            localStorage.setItem("user", JSON.stringify(userData));

            setAuthState({ isLoading: false, isAuthenticated: true, user: userData, handleLogout: handleLogout });
            return;
          }
          setAuthState({ isLoading: false, isAuthenticated: false, user: null, handleLogout: handleLogout });
          return;
        }
        throw new Error("error! auth url not supplied"); // if the AMS url is not present, we throw an error.
      } catch (error: any) {
        // if there is an error, we set the authState to isAuthenticated: false.
        console.error(error);
        setAuthState({ isLoading: false, isAuthenticated: false, user: null, handleLogout: handleLogout });
        return;
      }
    };
    getAuthState();


    // get the user data from the local storage.
    // user data is store in local storage
    const user = localStorage.getItem("user");
    if (!user) {
      setAuthState((prevState) => ({ ...prevState, isAuthenticated: false }));
    }
    // get the time when the user data was fetched from the local storage.
    // this is used to check if the user data is stale.
    // if the user data is stale, we will fetch the user data again.
    // if the user data is not stale, we will use the user data from the local storage.
    // this will prevent unnecessary calls to the backend.
    const lastFetched = localStorage.getItem("userFetchTime");

    // if the user data is not stale, we will use the user data from the local storage.
    if (user !== null && lastFetched !== null) {
      const currentTime = new Date(); // current time
      const lastFetchedTime = new Date(lastFetched); // last fetched time
      const diff = currentTime.getTime() - lastFetchedTime.getTime(); // difference in milliseconds
      const diffMinutes = Math.round(diff / 60000); // difference in minutes

      // if the difference in minutes is less than 60, we will use the user data from the local storage.
      // 60 minutes is the expiry time of the access token.
      if (diffMinutes < 60 && user) {
        const userData: UserData = JSON.parse(user);
        setAuthState({ isLoading: false, isAuthenticated: true, user: userData, handleLogout: handleLogout });
        return; // return from the useEffect if the user data is not stale.
      }
      // if the difference in minutes is greater than 60, we remove the user and userFetchTime from the local storage.
      // removing them from the local storage will force the app to fetch the user data again by calling the backend.
      else {
        localStorage.removeItem("user");
        localStorage.removeItem("userFetchTime");
      }
    }
  }, []);
  return authState;
}
